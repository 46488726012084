.seperator {
    margin: 10px 0px;
}

.container {
    display: "flex";
    align-items: center;
    height: 100vh;
    flex-direction: column;
    padding: 30px;
}