.listContainer {
    border-width: 5px;
    justify-content: center;
    height: 180px;
    align-items: center;
    margin: 15px 0px;
    display: flex;
    border-color: red;
    border: solid;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    overflow: auto;
}

.image {
    height: 100px;
    width: 80%;
    border-radius: 10px;
}